<template>
  <div style="max-height: 480px; overflow-y: auto">
    <a-table rowKey="uid" :columns="columns" :data-source="dataItems" size="small" :pagination="false">
      <template slot="goods" slot-scope="value, item, index">
        <GoodsSelect
          v-model="item.goods"
          :defaultItem="item.goods_item"
          size="small"
          style="width: 200px"
          @select="(goodsItem) => changeGoods(index, goodsItem)"
        />
      </template>
      <template slot="quantity" slot-scope="value, item">
        <a-input-number v-model="item.quantity" size="small" :min="0" @change="handleChange" />
      </template>
      <template slot="action" slot-scope="value, item, index">
        <TableAction :index="index" :item="item" @replace="replaceItem" @remove="removeItem" />
      </template>
      <template slot="footer">
        <a-button-group style="width: 100%">
          <a-button type="dashed" block @click="addItem">添加子产品</a-button>
          <a-button type="dashed" block @click="goodsCreateModalVisible = true">创建子产品</a-button>
        </a-button-group>
      </template>
    </a-table>

    <GoodsCreateModal v-model="goodsCreateModalVisible" @create="onCreateGoods" />
  </div>
</template>

<script>
export default {
  components: {
    GoodsSelect: () => import("@/components/GoodsSelect"),
    GoodsCreateModal: () => import("@/components/GoodsCreateModal"),
    TableAction: () => import("./TableAction"),
  },
  props: ["value"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      columns: [
        {
          title: "序号",
          width: 60,
          dataIndex: "index",
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "子产品",
          dataIndex: "goods",
          scopedSlots: { customRender: "goods" },
        },
        {
          title: "规格",
          dataIndex: "goods_spec",
          customRender: (_, item) => item.goods_item.spec,
        },
        {
          title: "尺寸",
          dataIndex: "drawing_number",
          customRender: (_, item) => item.goods_item.drawing_number,
        },
        {
          title: "数量",
          dataIndex: "quantity",
          scopedSlots: { customRender: "quantity" },
        },
        {
          title: "操作",
          width: 60,
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      goodsCreateModalVisible: false,
    };
  },
  methods: {
    addItem() {
      this.dataItems.push({
        uid: new Date().getTime(),
        goods: null,
        goods_item: {},
        quantity: 1,
      });
    },
    removeItem(index) {
      this.dataItems.splice(index, 1);
      this.handleChange();
    },
    replaceItem(index, item) {
      this.dataItems.splice(index, 1, item);
      this.handleChange();
    },
    changeGoods(index, item) {
      this.dataItems[index].goods_item = item;
      this.handleChange();
    },
    handleChange() {
      this.$emit("change", this.dataItems);
    },
    onCreateGoods(item) {
      this.dataItems.push({
        uid: new Date().getTime(),
        goods: item.id,
        goods_item: item,
        quantity: 1,
      });
      this.handleChange();
    },
  },
  watch: {
    value(items) {
      if (items) {
        this.dataItems = items.map((item) => ({ uid: item.id, ...item }));
      }
    },
  },
  mounted() {
    if (this.value) {
      this.dataItems = this.value.map((item) => ({ uid: item.id, ...item }));
    }
  },
};
</script>

<style scoped></style>
